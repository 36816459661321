/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-danger */
import React, { useEffect, useState, useContext } from 'react';
import ReactHTMLParser from 'react-html-parser';
import { graphql, useStaticQuery } from 'gatsby';
import { marked } from 'marked';
import { FeaturesSlider } from '@latitude/features-slider';
import PLData from '@/data/pages/personal-loan.json';
import HowToApplyList from '@/components/HowToApply/HowToApplyList';
import HowToApply from '@latitude/how-to-apply';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import CalculatorTile from '@/components/PersonalLoanSoftQuote/CalculatorTile';
import { EstimateRateWidgetSection } from '@/components/EstimateRateWidget/EstimateRateWidgetSection';
import { redirectUrl } from '@/components/EstimateRateWidget/utils';
import queryString from 'query-string';
import { Box } from '@latitude/box';
import { CardGroup, CardGroupItem } from '@/components/CardGroup/CardGroup';
import ArticleTile from '../../templates/life-done-better/ArticleTile';
import Faq from '@/components/Faq/Faq';

import ImportantInformation from '@/components/ImportantInformation/ImportantInformation';
import Layout from '@/components/layout';

import LoanRates from '@/components/LoanRates/LoanRates';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import { Heading4 } from '@/components/Heading/Heading';
import { List, ListItem } from '@/components/List';
import { Strong } from '@/components/Text/Bold';
import FeesAndCharges from '@/components/PersonalLoanDetails/PersonalLoanDetails';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  MARGIN,
  SOFT_QUOTE_LOANS_AU_URL
} from '../../utils/constants';
import { parseContent } from '../../utils/helpers';
import footerData from '@/data/json/footer.json';
import Section from '@latitude/section';
import { Hero } from '@latitude/hero';
import { HeroText } from '../HeroBranded/HeroBranded';
import heroImage from '../../images/hero/personal-loans.webp';
import styled from 'styled-components';
import Button from '../Button/Button';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { PageContext } from '@/context/PageContext';
import { NotificationBanner } from '@latitude/banner';
import { CONTENTFUL_COMPONENTS } from '../../utils/constants';
import { useSetState } from '@/utils/hooks';
import { selectDataSourceForAlertBanner } from '@/utils/helpers';

const HomeButton = styled(Button)`
  && {
    background-color: #21a637;
    color: #fff !important;
    white-space: nowrap;
    font-size: 22px;
  }

  @media (max-width: ${BREAKPOINT.MD}) {
    && {
      font-size: 15px;
      width: 100%;
      white-space: break-spaces;
    }
  }
  margin-bottom: 8px;
  &:hover {
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.4);
    transition: all 150ms ease-in-out;
  }
`;

// Shared eligibility criteria content
export const PLEligibilityCriteria = props => {
  const {
    isSecuredLoanOnly,
    isUnsecuredLoanOnly,
    customEligibilityCriteria
  } = props;
  const ListHeading = ({ children }) => {
    return (
      <Strong
        css={`
          display: block;
          margin: ${MARGIN.M8} 0;
        `}
      >
        {children}
      </Strong>
    );
  };

  // work out best rate section title
  let bestRateTitle = '';
  if (isSecuredLoanOnly) {
    bestRateTitle = PLData.content.eligibilityCriteriaForBestSecuredRateTitle;
  } else if (isUnsecuredLoanOnly) {
    bestRateTitle = PLData.content.eligibilityCriteriaForBestUnsecuredRateTitle;
  } else {
    bestRateTitle = PLData.content.eligibilityCriteriaForBestRateTitle;
  }

  // work out eligibility criteria list data
  let eligibilityCriteria = [];
  if (
    Array.isArray(customEligibilityCriteria) &&
    customEligibilityCriteria.length
  ) {
    eligibilityCriteria = [...customEligibilityCriteria];
  } else {
    eligibilityCriteria = [...PLData.content.eligibilityCriteria];
  }

  // This is to ensure that personal loan page always scroll to top
  // get started - soft quote - exit timeout - no to stop scroll point at get started button
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Heading4
        color={COLOR.BLACK}
        marginBottom={MARGIN.M16}
        isResponsive={false}
      >
        Eligibility
      </Heading4>

      <ListHeading>{PLData.content.eligibilityCriteriaTitle}</ListHeading>
      <List>
        {eligibilityCriteria.map((item, index) => {
          return <ListItem key={index}>{parseContent(item) || ''}</ListItem>;
        })}
      </List>
    </React.Fragment>
  );
};

const PersonalLoanPageContentLEFWaiver = props => {
  let queryParams;
  if (typeof window !== 'undefined') {
    queryParams = queryString.parse(location.search);
  }

  if (queryParams && queryParams.partnername) {
    typeof window !== 'undefined' &&
      sessionStorage.setItem('partnername', queryParams.partnername);
  } else {
    typeof window !== 'undefined' && sessionStorage.removeItem('partnername');
  }
  const applyUrl = `${getUrlFromEnv(
    'funnel-start-loans'
  )}${SOFT_QUOTE_LOANS_AU_URL}`;

  // soft quote
  const [softQuoteHref, setSoftQuoteHref] = useState();

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading: 'Why choose a Latitude loan?',
      featureCards: PLData.content.whyChooseLatitude
    }
  ];
  /** - END - */

  useEffect(() => {
    sessionStorage.setItem('purpose', 'purpose'); // reset session val used in `EstimateRateWidgetSection`
    setSoftQuoteHref(redirectUrl('au', true));
  }, []);

  const pageQuery = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: {
            glob: "**/src/templates/life-done-better/articles/*.md"
          }
          frontmatter: {
            title: {
              in: [
                "Understanding your credit score"
                "Get Personal: Choosing The Loan That's Right For You"
                "5 ways to improve your credit score"
              ]
            }
          }
        }
      ) {
        edges {
          node {
            html
            frontmatter {
              title
              path
              thumbnail
            }
          }
        }
      }
    }
  `);

  return (
    <Layout
      location={props.location}
      metaDesc={PLData.metaDesc}
      title={PLData.title}
      canonical={props.location.href}
      customFooter={footerData}
      noIndex
    >
      <main className="navigation-spacer">
        <Box backgroundColor={COLOR.GREY6}>
          <MobileAppInstallPrompts />
          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <Section
              css={`
                background-color: #0555c8;
                padding: 0;

                @media (max-width: ${BREAKPOINT.MD}) {
                  && > .container.section__content {
                    padding: 0;
                  }
                }
              `}
            >
              <Hero
                css={`
                  && {
                    color: #000;
                  }

                  h1 {
                    color: #fff;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 600;
                    font-family: Montserrat;
                    line-height: 3rem;
                  }

                  p {
                    color: #fff;
                    font-size: 19px;
                    font-weight: normal;
                  }

                  svg {
                    min-height: 45px;
                  }

                  .HeroContent {
                    padding: 64px 0px 117px;
                    line-height: normal;
                  }

                  [class^='Herostyles__HeroImage-'] img {
                    height: 90%;
                  }

                  .padding-right {
                    padding-right: 3rem;
                  }

                  @media (max-width: ${BREAKPOINT.MD}) {
                    h1,
                    .text-left,
                    p,
                    .col-12 {
                      color: #000 !important;
                    }

                    [class^='Herostyles__HeroImage-'] img {
                      height: 80%;
                    }

                    .padding-right {
                      padding-right: 1rem;
                    }

                    p {
                      font-size: 14px;
                      line-height: 1.3rem;
                    }

                    h1 {
                      font-size: 35px;
                      margin: 5px 0;
                    }

                    .conditional-text {
                      display: none;
                    }

                    .HeroContent {
                      padding: 10px;

                      p::after {
                        content: '.';
                      }

                      &__insettext {
                        font-size: 12px;
                        a.button {
                          font-size: 18px;
                        }
                      }
                    }

                    .text-center {
                      color: #000 !important;
                    }
                  }
                `}
                imageContent={
                  <div
                    css={`
                      height: 100%;
                      display: flex;
                      align-items: flex-end;
                      justify-content: center;
                      @media (min-width: ${BREAKPOINT.LG}) {
                        justify-content: flex-end;
                      }
                    `}
                  >
                    <img
                      src={heroImage}
                      alt=""
                      css={`
                        height: 250px;
                        width: auto;
                        @media (min-width: ${BREAKPOINT.MD}) {
                          height: 300px;
                        }
                        @media (min-width: ${BREAKPOINT.LG}) {
                          height: 382px;
                        }
                      `}
                    />
                  </div>
                }
                className="page-banner-lfs--bg"
              >
                {
                  <div
                    id="hero"
                    css={`
                && {
                  padding: 0 15px;
                  color: #fff:

                  @media (min-width: ${BREAKPOINT.LG}) {
                    padding: 0;
                  }
                }
              `}
                  >
                    <h1>Personal Loan</h1>
                    <HeroText
                      css={`
                        && {
                          color: #fff;
                          letter-spacing: -0.55px;
                          margin-right: 0px;
                        }
                      `}
                    >
                      Establishment fee waived.<sup>~</sup>
                      <br />
                      Save $395.
                    </HeroText>
                    <div className="row text-white">
                      <div className="col-6 col-md-4 text-left text-left">
                        <HomeButton
                          href={applyUrl}
                          trackId="personal-loans-button"
                          trackEventData={{
                            label: 'Get Rate Estimate',
                            location: 'Hero',
                            category: 'button-link'
                          }}
                          trackProductId={['PLAULF-WEB']}
                          className="button--primary"
                          css={`
                            width: fit-content;
                            padding: 0 10px;
                          `}
                        >
                          Get Rate Estimate
                        </HomeButton>
                      </div>
                    </div>
                  </div>
                }
              </Hero>
            </Section>
          )}

          <div
            className="d-none d-lg-block"
            css={`
              position: relative;
              z-index: 2;
            `}
          >
            <StickyNavigation
              items={PLData.nav}
              phoneNumber={PLData.content.phoneNumber}
              ctaHref={applyUrl}
              ctaText="Get Rate Estimate"
              offsetElem="[data-sticky-navigation-offset]"
              trackEventData={{
                category: 'cta',
                action: 'quote-link',
                location: 'sticky'
              }}
              trackId="sticky-get-started"
            />
          </div>

          <EstimateRateWidgetSection
            purpose="purpose"
            parentOnChangeEvent={() => {
              setSoftQuoteHref(redirectUrl());
            }}
          />
          <LoanRates
            isBranded
            rateBoxType="variableSymLEF"
            rateBoxType2="fixedSymLEF"
            fees={PLData.content.aboutTheLoan}
            extraTCs="true"
            determineRateLink
          />
          <FeesAndCharges data={PLData.content.feesAndChargesLEF} />
          <CalculatorTile />
          <FeaturesSlider
            key={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            heading={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            id="why-us"
            className="why-choose bg-f8f8f8"
            subheading={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .description
            }
            data={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .featureCards
            }
          />

          <HowToApply
            id="how-to-apply"
            box1={<HowToApplyList variant="sym" />}
            box2={<PLEligibilityCriteria />}
          />

          <Box.Section
            backgroundColor={COLOR.GREY5}
            paddingTop={MARGIN.M32}
            paddingBottom={MARGIN.M32}
          >
            <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
              Recommended reads
            </Heading4>
            <CardGroup>
              {pageQuery.allMarkdownRemark.edges.map(item => (
                <CardGroupItem key={item.node.frontmatter.path}>
                  <ArticleTile isBranded {...item.node.frontmatter} />
                </CardGroupItem>
              ))}
            </CardGroup>
          </Box.Section>
          <Faq data={PLData.content.faq} />
          <ImportantInformation
            data={require('@/data/json/disclaimer/personal-loan-lef-waiver.json')}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
            additionalPaymentsDisclaimerOne
            additionalPaymentsDisclaimerThree
          />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'LoanOrCredit',
                name: PLData.content.loanDetails.personal.name,
                loanTerm: PLData.content.loanDetails.personal.loanTerm,
                annualPercentageRate:
                  PLData.content.loanDetails.personal.annualPercentageRate,
                amount: PLData.content.loanDetails.personal.amount
              })
            }}
          />
        </Box>
      </main>
    </Layout>
  );
};

export default PersonalLoanPageContentLEFWaiver;
